.calendar-page .calendar-container .timeGridDay-container {
  display: flex !important;
  flex-direction: row !important;
  background-color: white !important;
  flex: 1 !important;
  height: 100% !important;
  border: 1px solid #ccc;
}
.calendar-page .calendar-container .timeGridDay-container .appo-status {
  flex: 1 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 1px 6px 1px 6px !important;
  height: 100% !important;
  min-height: 30px !important;
  max-width: 112px !important;
  min-width: 112px !important;
  background: #f2f2f2 !important;
  border: 1px solid #ccc;
}
.calendar-page .calendar-container .timeGridDay-container .appo-status p {
  font-size: 12px !important;
  line-height: 14.72px !important;
  color: #444444 !important;
  font-family: "Emprint-semibold" !important;
  margin: 0px !important;
}
.calendar-page .calendar-container .timeGridDay-container .appo-info {
  flex: 1 !important;
  display: flex !important;
  gap: 30px;
  align-items: center !important;
  padding: 1px 6px 1px 6px !important;
  height: 100% !important;
  min-height: 30px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
  background: #f9f9fa !important;
}
.calendar-page .calendar-container .timeGridDay-container .appo-info p {
  font-size: 12px !important;
  line-height: 14.72px !important;
  color: rgba(68, 68, 68, 1) !important;
  font-family: "Emprint-regular" !important;
  margin: 0px !important;
  max-height: 14px !important;

  max-lines: 1 !important;

  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
.calendar-page
  .calendar-container
  .timeGridDay-container
  .appo-info
  .vehicle-info {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 830px) {
  .calendar-page .calendar-container .timeGridDay-container .appo-status {
    min-width: 50px !important;
    max-width: 1000px !important;
  }
  .calendar-page .calendar-container .timeGridDay-container .appo-info {
    display: none !important;
  }
  .calendar-page .calendar-container .timeGridDay-container .appo-status p {
    font-size: 10px !important;
    line-height: 12.27px !important;
    font-family: "Emprint-semibold" !important;
    margin: 0px !important;
  }
}

@media only screen and (max-width: 375px) {
  .calendar-page .calendar-container .timeGridDay-container .appo-status p {
    font-size: 8px !important;
    line-height: 10.27px !important;
    font-family: "Emprint-semibold" !important;
    margin: 0px !important;
  }
}

.calendar-page .calendar-container .timeGridDay-container .status-dot {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin-right: 4px;
}
