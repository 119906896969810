/* -------------------------------------- */
/*                                        */
/*            CALENDAR HEADER             */
/*                                        */
/* -------------------------------------- */
.calendar-page .calendar-container .calendar-header .for-web {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.calendar-page .calendar-container .calendar-header .for-mob {
  display: none;
}
.calendar-page .calendar-container .calendar-header {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.calendar-page .calendar-container .calendar-header .buttons-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px !important;
}
.calendar-page .calendar-container .calendar-header .left-options {
  display: block;
  width: 100%;
}
.calendar-page .calendar-container .calendar-header .right-options {
  width: 100%;
}
.calendar-page
  .calendar-container
  .calendar-header
  .left-options
  .switch-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}
.calendar-page .calendar-container .calendar-header .buttons-option .label {
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .calendar-page .calendar-container .calendar-header .buttons-option .label {
    font-size: 12px !important;
  }
}
.calendar-page .calendar-container .calendar-header .right-options .label {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .calendar-page .calendar-container .calendar-header .right-options .label {
    font-size: 12px !important;
  }
}
.calendar-page .calendar-container .calendar-header .divider {
  margin: 9px 0px 20px 0px;
  background: #ccc;
}
.calendar-page .calendar-container .calendar-header .add-appo {
  width: 180px !important;
  height: 40px !important;
}
.calendar-page .calendar-container .calendar-header .add-appo span {
  font-size: 16px !important;
  font-family: "Emprint-semibold";
}
.calendar-page
  .calendar-container
  .calendar-header
  .right-options
  .type-select-reload {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px !important;
}
.calendar-page .ant-popover-inner-content {
  padding: 0px !important;
}

.calendar-page
  .calendar-container
  .calendar-header
  .right-options
  .type-select-reload
  .popover-button {
  padding: 0px;
  width: 115px !important;
  height: 30px !important;
  background-color: white;
  border: 1px solid #888888;
  border-radius: 6px;
}
.calendar-page
  .calendar-container
  .calendar-header
  .right-options
  .type-select-reload
  .is-open-hideborder {
  border-radius: 10px 10px 0px 0px !important;
}
.calendar-page
  .calendar-container
  .calendar-header
  .right-options
  .type-select-reload
  .popover-button
  span {
  font-size: 14px !important;
  font-weight: 400;
  color: #000;
}
.calendar-page .calendar-container .calendar-header .title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 0px;
  width: 100%;
}
.calendar-page .calendar-container .calendar-header .title-container .title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.calendar-page .calendar-container .calendar-header .title-container .title h3 {
  color: #475569;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  margin: 0 10px 0 0 !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.5px;
}
@media (max-width: 920px) {
  .calendar-page
    .calendar-container
    .calendar-header
    .title-container
    .title
    h3 {
    width: calc(100% - 100px);
    margin: 0px !important;
  }
}
.calendar-page
  .calendar-container
  .calendar-header
  .filters-container
  .select-range {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media (max-width: 920px) {
  .calendar-page
    .calendar-container
    .calendar-header
    .filters-container
    .select-range {
    display: block !important;
  }
}
.calendar-page
  .calendar-container
  .calendar-header
  .filters-container
  .select-range
  .view-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media (max-width: 992px) {
  .calendar-page
    .calendar-container
    .calendar-header
    .filters-container
    .select-range
    .view-options {
    margin-top: 5px !important;
  }
}
.calendar-page
  .calendar-container
  .calendar-header
  .filters-container
  .select-range
  .label {
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .calendar-page
    .calendar-container
    .calendar-header
    .filters-container
    .select-range
    .label {
    font-size: 12px !important;
  }
}
.calendar-page
  .calendar-container
  .calendar-header
  .filters-container
  .select-range
  .range-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 30px;
  cursor: pointer;
}
.calendar-page
  .calendar-container
  .calendar-header
  .filters-container
  .select-range
  .range-option.option-selected span {
  font-weight: 600;
}
/* @media (max-width: 992px) {
  .calendar-page
    .calendar-container
    .calendar-header
    .filters-container
    .select-range
    .range-option {
    font-size: 14px !important;
  }
} */
.calendar-page
  .calendar-container
  .calendar-header
  .filters-container
  .select-range
  .option-selected {
  background-color: var(--primary-color);
  color: #fff;
}
.calendar-page
  .calendar-container
  .calendar-header
  .title-container
  .title
  .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90px;
}
.calendar-page
  .calendar-container
  .calendar-header
  .title-container
  .title
  .buttons 
  svg {
  width: 16px;
  height: 12px;
}
@media (max-width: 920px) {
  .calendar-page
    .calendar-container
    .calendar-header
    .title-container
    .title
    .buttons {
    width: 100% !important;
    justify-content: space-around !important;
    align-items: center !important;
  }
}
.calendar-page
  .calendar-container
  .calendar-header
  .title-container
  .title
  .prev-button {
  width: 40px;
  height: 40px;
  border-width: 2px;
}
.calendar-page
  .calendar-container
  .calendar-header
  .title-container
  .title
  .next-button {
  width: 40px;
  height: 40px;
  border-width: 2px;
}

@media only screen and (max-width: 992px) {
  .calendar-page .calendar-container .calendar-header .for-web {
    display: none;
  }
  .calendar-page .calendar-container .calendar-header .for-mob {
    display: block;
  }
  .calendar-page .calendar-container .calendar-header .title-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 15px 0px;
    width: 100%;
  }
  .calendar-page
    .calendar-container
    .calendar-header
    .title-container
    .filter-mob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .calendar-page .calendar-container .calendar-header .buttons-option {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    gap: 10px !important;
    display: flex;
    align-content: center;
  }
  .calendar-page .calendar-container .calendar-header .add-appo {
    width: 240px !important;
    max-width: 240px;
  }
  .calendar-page
    .calendar-container
    .calendar-header
    .right-options
    .type-select-reload
    .popover-button {
    width: 240px !important;
  }
  .calendar-page .calendar-container .calendar-header .title-container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
  }
  .calendar-page
    .calendar-container
    .calendar-header
    .right-options
    .type-select-reload {
    gap: 20px !important;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .calendar-page .calendar-container .calendar-header .right-options {
    height: 100% !important;
    margin-bottom: 15px !important;
  }
}

@media (max-width: 450px) {
  .calendar-page .calendar-container .calendar-header .buttons-option {
    flex-direction: column;
  }
  .calendar-page .calendar-container .calendar-header .add-appo,
  .calendar-page
    .calendar-container
    .calendar-header
    .right-options
    .type-select-reload
    .popover-button {
    width: 100% !important;
    max-width: 300px !important;
  }
}
/* -------------------------------------- */
/*                                        */
/*          END CALENDAR HEADER           */
/*                                        */
/* -------------------------------------- */

/* -------------------------------------- */
/*                                        */
/*             POPOVER STYLE              */
/*                                        */
/* -------------------------------------- */
.popover-status-content-ag-ws {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: 1px solid #888888;
  margin-top: -6px !important;
  gap: 10px !important;
  width: 170px;
  padding: 7px 10px !important;
  border-radius: 0px 0px 10px 10px !important;
  background-color: white;
}
.popover-status-content-ag-ws .last {
  border: 0px;
  border-top: 1px solid #444444;
  border-style: dashed;
  padding-top: 10px;
  width: 100%;
}
.popover-status-content-ag-ws div .ant-checkbox-wrapper .ant-checkbox span {
  width: 20px !important;
  height: 20px !important;
  border-radius: 5px !important;
  border: 2px solid #444444 !important;
  background-color: white;
  font-family: "Emprint-semibold" !important;
}
.popover-status-content-ag-ws div .ant-checkbox-wrapper span p {
  font-family: "Emprint-semibold" !important;
}
.popover-status-content-ag-ws
  div
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-inner::after {
  scale: 1.5 !important;
  border-color: #444444 !important;
  margin-top: 2px;
}
.popover-status-content-ag-ws
  div
  .check-custom-CREAT
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--CREAT-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-PEND
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--PEND-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-APPO
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--APPO-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-RECEP
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--RECEP-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-DIAG
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--DIAG-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-QUOT
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--QUOT-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-PROG
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--PROG-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-READY
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--READY-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-CANCE
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--CANCE-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-CLOS
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--CLOS-color) !important;
}
.popover-status-content-ag-ws
  div
  .check-custom-ALL
  .ant-checkbox
  .ant-checkbox-inner::after {
  border-color: var(--ALL-color) !important;
}

@media only screen and (max-width: 992px) {
  .popover-status-content-ag-ws {
    width: 300px !important;
  }
}
/* -------------------------------------- */
/*                                        */
/*          END POPOVER STYLE             */
/*                                        */
/* -------------------------------------- */
