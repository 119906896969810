.calendar-page .calendar-container .dayGridMonth-container {
  display: flex;
  flex-direction: column;
  width: inherit !important;
}

.status-dot {
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
  background: red;
}

.calendar-page .calendar-container .dayGridMonth-container .header {
  display: flex;
  align-items: center;
  gap: 3px;
  flex-direction: row;
  padding-left: 4px;
  padding-right: 4px;
  color: #444;
  background: #f0f0f0;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ccc;
  text-overflow: ellipsis;
  overflow: hidden;
}

.calendar-popover-container {
  display: flex;
  flex-direction: column;
  width: 217px;
  cursor: pointer;
}

.calendar-popover-container .header {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #000;
  background: #f0f0f0;
  font-size: 14px;
}

.calendar-popover-container .body {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.calendar-popover-container .status-info {
  display: flex;
  gap: 5px;
  align-items: center;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
  font-weight: 600;
  font-size: 12px;
}

.calendar-popover-container .time-info {
  margin-top: 8px;
  color: #444;
  font-weight: 600;
  font-size: 12px;
}

.calendar-popover-container .vehicle-info {
  text-transform: capitalize;
  color: #444;
  font-size: 12px;
}

.calendar-popover-container .problem-info {
  text-transform: capitalize;
  color: #444;
  font-size: 12px;
}
