.main-disable-scroll {
  overflow-y: hidden !important;
}
.calendar-page {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  height: calc(100vh - 91px);
  background-color: white;
  position: relative !important;
  top: 0;
  left: 0;
  text-size-adjust: 100%;
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden !important;
}
@media (max-width: 992px) {
  .calendar-page {
    min-height: calc(100vh - 126px);
    overflow: visible;
  }
}

.calendar-page .spin-container {
  display: flex;
  z-index: 9999;
  position: absolute;
  top: 19%;
  left: 50%;
}

@media (max-width: 920px) {
  .calendar-page {
    margin: auto;
    padding: 20px 5px;
    min-height: calc(100vh - 116px);
  }
}

.calendar-page .calendar-container {
  width: 100% !important;
  height: 100% !important;
}
.calendar-page .calendar-view {
  height: calc(100vh - 235px) !important;
}

@media (max-width: 992px) {
  .calendar-page .calendar-view {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991px) {
  .calendar-page .calendar-view {
    height: calc(100vh - 320px) !important;
  }
}

@media (max-width: 450px) {
  .calendar-page .calendar-view {
    height: calc(100vh - 370px) !important;
  }
}

.calendar-page .calendar-view,
.calendar-page .AppoGridView-container {
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
  height: auto;
  opacity: 1;
}
.calendar-page .calendar-view.hidden,
.calendar-page .AppoGridView-container.hidden {
  transition: opacity 0.5s ease;
  height: 0;
  opacity: 0;
}

.calendar-hidden {
  display: none;
}

/* -------------------------------------- */
/*                                        */
/*              TABLE STYLES              */
/*                                        */
/* -------------------------------------- */

.fc .fc-view-harness {
  height: 100% !important;
}
.calendar-page
  .calendar-container
  .fc
  .fc-view-harness
  .fc-timegrid
  .fc-scrollgrid
  thead
  tr
  th
  .fc-scroller-harness
  .fc-scroller
  table
  thead
  tr
  th {
  border: none !important;
}
.calendar-page
  .calendar-container
  .fc
  .fc-view-harness
  .fc-timegrid
  .fc-scrollgrid {
  border: none !important;
}
.calendar-page
  .calendar-container
  .fc
  .fc-view-harness
  .fc-timegrid
  .fc-scrollgrid
  thead
  tr
  th
  .fc-scroller-harness
  .fc-scroller
  table
  thead
  tr
  th {
  border: none !important;
  font-weight: 600 !important;
  color: var(--mariner-color) !important;
}
.calendar-page .calendar-container .fc-timegrid-slot {
  height: 60px !important;
}
.fc .fc-scrollgrid-liquid {
  height: 92% !important;
}
.calendar-page
  .calendar-container
  .fc
  .fc-view-harness
  .fc-timegrid
  .fc-scrollgrid
  thead
  tr
  th
  .fc-scroller-harness
  .fc-scroller
  table
  thead
  tr
  th
  div
  a {
  font-weight: 700 !important;
  font-family: "EMPrint";
  font-size: 20px !important;
  line-height: 20px !important;
}
@media (max-width: 920px) {
  .calendar-page
    .calendar-container
    .fc
    .fc-view-harness
    .fc-timegrid
    .fc-scrollgrid
    thead
    tr
    th
    .fc-scroller-harness
    .fc-scroller
    table
    thead
    tr
    th
    div
    a {
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 2px;
  }
}
.calendar-page
  .calendar-container
  .fc
  .fc-view-harness
  .fc-timegrid
  .fc-scrollgrid
  thead
  tr
  th
  .fc-scroller-harness
  .fc-scroller
  table
  thead
  tr
  .fc-day-today
  div
  a {
  color: var(--mariner-color) !important;
}
.calendar-page
  .calendar-container
  .fc
  .fc-view-harness
  .fc-timegrid
  .fc-scrollgrid
  tbody
  .fc-scrollgrid-section
  td
  .fc-scroller-harness
  .fc-scroller
  .fc-timegrid-body
  .fc-timegrid-slots
  table
  tbody
  tr
  td
  .fc-timegrid-slot-label-frame
  .fc-timegrid-slot-label-cushion
  p {
  font-size: 12px !important;
  line-height: 15px !important;
  color: #444444;
  text-transform: uppercase;
  margin: 0px !important;
  font-weight: 400 !important;
  font-family: "EMPrint";
}
@media (max-width: 920px) {
  .calendar-page
    .calendar-container
    .fc
    .fc-view-harness
    .fc-timegrid
    .fc-scrollgrid
    tbody
    .fc-scrollgrid-section
    td
    .fc-scroller-harness
    .fc-scroller
    .fc-timegrid-body
    .fc-timegrid-slots
    table
    tbody
    tr
    td
    .fc-timegrid-slot-label-frame
    .fc-timegrid-slot-label-cushion
    p {
    font-size: 10px !important;
    line-height: 12px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
.calendar-page .calendar-container .fc-day-today {
  background-color: #ebf5ff;
}
.fc-timeGridDay-view .fc-timegrid-event {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.fc-timeGridWeek-view .fc-timegrid-event {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.calendar-page .calendar-container .fc-col-header-cell-cushion {
  font-size: 20px !important;
  line-height: 24.54px !important;
  color: var(--mariner-color) !important;
  font-weight: 700 !important;
}
.calendar-page .calendar-container .fc-col-header-cell-cushion::first-letter {
  text-transform: uppercase;
}
.calendar-page .calendar-container .fc-daygrid-day-top {
  justify-content: start;
}
.calendar-page .calendar-container .fc-daygrid-day-number {
  font-weight: 700 !important;
  font-family: "EMPrint";
  font-size: 20px;
  line-height: 25px;
  color: #444444 !important;
}
@media (max-width: 992px) {
  .calendar-page .calendar-container .fc-col-header-cell-cushion {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
  }

  .calendar-page .calendar-container .fc-daygrid-day-number {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    margin-top: 10px;
  }
}
.calendar-page .calendar-container .fc-list-event-time {
  display: none !important;
}
.calendar-page .calendar-container .fc-list-event-graphic {
  display: none !important;
}

/* -------------------------------------- */
/*                 TO DAY                 */
/* -------------------------------------- */
.calendar-page
  .calendar-container
  .fc-timeGridDay-view
  .fc-popover
  .fc-popover-header
  .fc-popover-title {
  color: var(--mariner-color) !important;
  font-weight: 600;
}
.calendar-page .calendar-container .fc-timeGridDay-view .fc-timegrid-more-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* border: 1px solid var(--orange) !important; */
  border-radius: 10px 0px 0px 10px !important;
  height: 32px !important;
  min-width: 35px !important;
  padding: 0px !important;
  background-color: var(--primary-color) !important;
}
.calendar-page
  .calendar-container
  .fc-timeGridDay-view
  .fc-timegrid-more-link:hover {
  /* background-color: rgba(68, 68, 68, 0.8) !important; */
}
.calendar-page
  .calendar-container
  .fc-timeGridDay-view
  .fc-timegrid-more-link
  .fc-timegrid-more-link-inner {
  text-align: center !important;
  color: #fff !important;
  padding: 0px !important;
  width: 100% !important;
  font-weight: 600 !important;
  font-family: "EMPrint";
}
/* -------------------------------------- */
/*               END TO DAY               */
/* -------------------------------------- */
/* -------------------------------------- */
/*                TO WEEK                 */
/* -------------------------------------- */
.calendar-page
  .calendar-container
  .fc-timeGridWeek-view
  .fc-popover
  .fc-popover-header
  .fc-popover-title {
  color: var(--mariner-color) !important;
  font-weight: 600;
}
.calendar-page
  .calendar-container
  .fc-timeGridWeek-view
  .fc-timegrid-more-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* border: 1px solid var(--orange) !important; */
  border-radius: 10px !important;
  height: 25px !important;
  min-width: 25px !important;
  padding: 3px !important;
  background-color: var(--primary-color) !important;
}
.calendar-page
  .calendar-container
  .fc-timeGridWeek-view
  .fc-timegrid-more-link:hover {
  /* background-color: rgba(68, 68, 68, 0.8) !important; */
}
.calendar-page
  .calendar-container
  .fc-timeGridWeek-view
  .fc-timegrid-more-link
  .fc-timegrid-more-link-inner {
  font-size: 12px;
  line-height: 15px;
  text-align: center !important;
  color: #fff !important;
  padding: 0px !important;
  width: 100% !important;
  font-weight: 600 !important;
  font-family: "EMPrint";
}

/* -------------------------------------- */
/*              END TO WEEK               */
/* -------------------------------------- */
/* -------------------------------------- */
/*               TO MONTH                 */
/* -------------------------------------- */
.calendar-page
  .calendar-container
  .fc-dayGridMonth-view
  .fc-daygrid-day-bottom
  .fc-daygrid-more-link {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  display: block;
  margin: 2px auto 10px auto;
  width: 70%;
  text-align: center;
  background: var(--primary-color);
  border-radius: 10px;
  padding: 4px;
  font-weight: 600 !important;
  font-family: "EMPrint";
}
.calendar-page
  .calendar-container
  .fc-dayGridMonth-view
  .fc-daygrid-day-bottom
  .fc-daygrid-more-link:hover {
  /* background: rgba(68, 68, 68, 0.8) !important; */
}
.calendar-page .calendar-container .fc-dayGridMonth-view .fc-daygrid-day-frame {
  height: 140px;
}
.calendar-page
  .calendar-container
  .fc-dayGridMonth-view
  .fc-daygrid-day.fc-day-other {
  background: #fafafa;
  background-clip: content-box;
}
.calendar-page
  .calendar-container
  .fc-dayGridMonth-view
  .fc-daygrid-day.fc-day-past:not(.fc-day-other) {
  /* background: rgba(47, 102, 242, 0.1); */
}
.calendar-page
  .calendar-container
  .fc-dayGridMonth-view
  .fc-daygrid-day.fc-day-today {
  /* background: rgba(240, 88, 34, 0.1); */
  background: rgba(47, 102, 242, 0.1);
}

@media only screen and (max-width: 991px) {
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-daygrid-day-bottom
    .fc-daygrid-more-link {
    display: block;
    word-break: break-word;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: white !important;
    font-weight: 600 !important;
    font-family: "EMPrint";
    border-radius: 50px !important;
    padding: 2px !important;
    max-width: 25px !important;
    max-height: 25px !important;
    background-color: var(--primary-color) !important;
    position: absolute;
    top: 0px;
    left: 14px;
    margin: 0;
    overflow: hidden;
  }
  /* 
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-daygrid-day-events {
    height: 0;
    min-height: unset;
    margin: 0;
    padding: 0;
  } 
  
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-scrollgrid-sync-table
    tbody
    tr {
    overflow: hidden;
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;
  }
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-scrollgrid-sync-table
    tbody
    td {
    border: 0 !important;
    overflow: hidden;
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;
  }
  */
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-daygrid-day-frame {
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;
  }
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-daygrid-event-harness.fc-daygrid-event-harness-abs {
    height: 0;
  }
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-daygrid-day-bottom {
    padding: 0;
  }
}
@media (max-width: 420px) {
  .calendar-page
    .calendar-container
    .fc-dayGridMonth-view
    .fc-daygrid-day-bottom
    .fc-daygrid-more-link {
    font-size: 10px;
    max-width: 22px !important;
    max-height: 22px !important;
    line-height: 22px;
    padding: 0 !important;
  }
}
/* -------------------------------------- */
/*             END TO MONTH               */
/* -------------------------------------- */
/* -------------------------------------- */
/*                                        */
/*            END TABLE STYLES            */
/*                                        */
/* -------------------------------------- */

/* -------------------------------------- */
/*                                        */
/*           POPOVER MORE ITEMS           */
/*                                        */
/* -------------------------------------- */
.calendar-page .calendar-container .fc-popover {
  border-radius: 10px !important;
  background-color: white !important;
}
.calendar-page .calendar-container .fc-popover .fc-popover-header {
  border-radius: 10px 10px 0px 0px !important;
}
.calendar-page .calendar-container .fc-popover .fc-popover-body {
  overflow-y: scroll !important;
  max-height: 150px !important;
}
/* -------------------------------------- */
/*                                        */
/*         END POPOVER MORE ITEMS         */
/*                                        */
/* -------------------------------------- */

/* -------------------------------------- */
/*              More popover              */
/* -------------------------------------- */
.fc-popover.fc-more-popover {
  z-index: 900 !important;
}

/* -------------------------------------- */
/*          Appointment popover           */
/* -------------------------------------- */
.appointment-popover .ant-popover-inner {
  border: 1px solid #888888;
  border-radius: 10px;
}
.appointment-popover .ant-popover-inner-content {
  padding: 20px !important;
  position: relative;
  top: 0;
  left: 0;
  min-width: 300px;
  font-family: "EMPrint";
  color: #444444;
}
.appointment-popover .ant-popover-inner-content .ant-btn-ghost {
  border: 0;
  box-shadow: unset;
  position: absolute;
  right: 15px;
  top: 5px;
  width: 20px;
}
.appointment-popover .ant-popover-inner-content .appointment-status {
  font-family: "EMPrint";
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 10px;
}
.appointment-popover .ant-popover-inner-content p {
  font-weight: 600 !important;
  font-family: "EMPrint";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.appointment-popover .ant-popover-inner-content .appointment-date {
  text-align: center;
  margin-bottom: 20px;
}
.appointment-popover .ant-popover-inner-content .appointment-data-group {
  margin-bottom: 20px;
}
.appointment-popover .ant-popover-inner-content .appointment-data-group .value {
  color: var(--mariner-color);
}
.appointment-popover .ant-popover-inner-content .ant-btn.--custom {
  display: block;
  margin: 0 auto 10px;
  width: 200px;
}
.appointment-popover .ant-popover-inner-content .ant-btn.--custom span {
  font-weight: 600 !important;
  font-family: "EMPrint";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.ant-tooltip.appointment-tooltip {
  min-width: 300px;
  z-index: 9999;
}

.appointment-tooltip .ant-tooltip-inner {
  padding: 20px !important;
  position: relative;
  top: 0;
  left: 0;
  min-width: 300px;
  font-family: "EMPrint";
  color: #444444;
  background: #fff;
  border: 1px solid #888888;
  opacity: 1;
  border-radius: 10px;
  overflow: hidden;
}
.appointment-tooltip .ant-tooltip-inner .ant-btn-ghost {
  border: 0;
  box-shadow: unset;
  position: absolute;
  right: 15px;
  top: 5px;
  width: 20px;
}
.appointment-tooltip .ant-tooltip-inner .appointment-status {
  font-weight: 600 !important;
  font-family: "EMPrint";
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 10px;
}
.appointment-tooltip .ant-tooltip-inner p {
  font-weight: 600 !important;
  font-family: "EMPrint";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.appointment-tooltip .ant-tooltip-inner .appointment-date {
  text-align: center;
  margin-bottom: 20px;
}
.appointment-tooltip .ant-tooltip-inner .appointment-data-group {
  margin-bottom: 20px;
}
.appointment-tooltip .ant-tooltip-inner .appointment-data-group .value {
  color: var(--mariner-color);
}
.appointment-tooltip .ant-tooltip-inner .ant-btn.--custom {
  display: block;
  margin: 0 auto 10px;
  width: 200px;
}
.appointment-tooltip .ant-tooltip-inner .ant-btn.--custom span {
  font-weight: 600 !important;
  font-family: "EMPrint";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.fc .fc-timegrid-slot-minor {
  border: 1px solid #cccccc;
  border-top-style: dashed;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #cccccc;
}
@media (max-width: 992px) {
  .fc-theme-standard td,
  .fc-theme-standard th {
    border-left: none !important;
    border-right: none !important;
  }
}
@media (max-width: 992px) {
  .calendar-page .calendar-container .fc-daygrid-day-top {
    justify-content: center !important;
  }
}

.fc .fc-timegrid-now-indicator-line {
  border-color: var(--primary-color) !important;
}

.fc .fc-col-header-cell {
  border-left: none !important;
  border-right: none !important;
  background-color: #fff !important;
}

.fc .fc-timegrid-axis {
  border-right: none !important;
  border-bottom: none !important;
}

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  display: none !important;
}

.fc .fc-timegrid-slot-label {
  vertical-align: baseline !important;
}
