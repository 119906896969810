.mobile-week-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-week-list .event-container {
  display: flex;
  gap: 10px;
}

.mobile-week-list .event-container .time {
  color: #444;
  font-size: 12px;
}

.mobile-week-list .event-container .card-container {
  height: 68px;
  width: 277px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.mobile-week-list .day-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 10px 0px 10px 0px;
}

.mobile-week-list .event-container .card-container .header {
  display: flex;
  align-items: center;
  height: 25px;
  color: #444;
  font-size: 12px;
  font-weight: 600;
  background-color: #f2f2f2;
}

.mobile-week-list .event-container .card-container .body {
  color: #444;
  font-size: 12px;
  padding: 3px 0px 3px 10px;
}

.mobile-week-list .date-title {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .mobile-week-list {
    display: none;
  }
}

.mobile-week-list .event-container .card-container .status-dot {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin-right: 5px;
  margin-left: 5px;
}
