.calendar-page .calendar-container .fc-list-event-title {
  padding: 0px !important;
  border: none !important;
}

.listMonth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  background: #fff;
}

.listMonth-container .card-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 200px;
  height: 257px;
  border-radius: 10px !important;
  border: 1px solid var(--primary-color) !important;
  overflow: hidden;
  padding: 10px;
}

.listMonth-container .card-container .card-head-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.listMonth-container .card-container .card-head-container .card-head {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 6.463px;
  background: #f2f2f2;
  color: #444;
  font-weight: 600;
  font-size: 12px;
  padding: 2px 5px 2px 5px;
}

.listMonth-container
  .card-container
  .card-head-container
  .card-head
  .appo-status {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
}

.listMonth-container .card-container .card-body {
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 5px;
}

.listMonth-container .card-container .card-body .contact-info {
  display: flex;
  gap: 5px;
}

.listMonth-container .card-container .card-actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listMonth-container .card-container .card-actions .card-button {
  min-width: 155px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  border-radius: 6.463px;
  border: none !important;
}

.listMonth-container .card-container .card-actions .card-button > span {
  color: #fff;
  font-size: 13.54px;
  font-weight: 600;
}

.listMonth-container .card-container .card-body .appo-date {
  color: #475569;
  font-size: 12px;
  font-weight: 700;
}

/* Icons contact info */
.listMonth-container .card-container .card-body div > span {
  color: var(--mariner-color);
  width: 14px;
  height: 14px;
}

.listMonth-container .card-container .appo-label {
  color: #444;
  font-size: 10px;
}

.listMonth-container .card-container .contact-label {
  color: #444;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.listMonth-container .card-container .contact-label .anticon,
.listMonth-container .card-container .contact-label:hover {
  color: var(--mariner-color);
}
.listMonth-container .card-container .contact-label .anticon,
.listMonth-container .card-container .contact-label .anticon svg {
  width: 16px;
  height: auto;
}

.listMonth-container .card-container .appo-link {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.listMonth-container .card-container .status-dot {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin-right: 4px;
}

.card-actions-popover {
  display: flex;
  width: 162px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 10px;
}

.card-actions-popover .ant-btn {
  padding: 0;
  border: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  box-shadow: none;
}

.card-actions-popover .ant-btn span {
  color: var(--primary-color);
  font-size: 16px !important;
}

.card-actions-popover .ant-btn span.anticon {
  height: 25px;
  width: 25px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.card-actions-popover .ant-btn span.anticon svg {
  width: 22px;
}

.card-actions-popover .popover-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  height: 32px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  z-index: 25 !important;
}
