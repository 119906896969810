.calendar-page .calendar-container .time-grid-week-card {
  height: 100% !important;
  width: inherit !important;
  border: 1px solid #cccccc;
  background-color: #fff;
  overflow: hidden;
}

.calendar-page .calendar-container .time-grid-week-card .header {
  min-height: 30px;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  color: #444;
  font-size: 12px;
  font-weight: 600;
}

.calendar-page .calendar-container .time-grid-week-card .body {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: #fff;
  color: #444;
}

.calendar-page .calendar-container .time-grid-week-card .body > div {
  margin-left: 10px;
}

.calendar-page .calendar-container .time-grid-week-card .status-dot {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin-right: 4px;
  margin-left: 4px;
}
