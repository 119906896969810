.calendar-page .calendar-container .AppoGridView-container {
  display: flex;
  gap: 20px;
  overflow: hidden;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.calendar-page .calendar-container .AppoGridView-container .section {
}

.calendar-page .calendar-container .AppoGridView-container .section .title {
  height: 30px;
  width: 100%;
  min-width: 420px;
  background-color: #eaeffe;
  padding: 4px 0px 4px 20px;
  color: #282828;
  font-size: 16px;
  font-weight: 600;
}

.calendar-page
  .calendar-container
  .AppoGridView-container
  .section
  .appointments-container {
  display: flex;
  gap: 10px;
  margin-top: 18px;
  justify-content: space-between;
}

.calendar-page
  .calendar-container
  .AppoGridView-container
  .appointments-container
  .column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.calendar-page
  .calendar-container
  .AppoGridView-container
  .section
  .appointments-container
  .column
  .column-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 200px;
  background-color: #f2f2f2;
  color: #444;
  font-size: 14px;
  font-weight: 600;
  min-height: 30px;
}

.calendar-page
  .calendar-container
  .AppoGridView-container
  .section
  .appointments-container
  .column
  .column-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  min-height: 50px;
}

.calendar-page .calendar-container .AppoGridView-container .scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  color: #fff;
  user-select: none;
  position: absolute;
  margin-top: 3px;
  padding: 0;
}

.calendar-page .calendar-container .AppoGridView-container .scroll svg {
  height: 15px;
  width: auto;
}
.calendar-page .calendar-container .AppoGridView-container .left-arrow {
  left: -11px;
}
.calendar-page .calendar-container .AppoGridView-container .left-arrow .anticon {
  margin-right: 3px;
}
.calendar-page .calendar-container .AppoGridView-container .right-arrow {
  right: -11px;
}
.calendar-page .calendar-container .AppoGridView-container .right-arrow .anticon {
  margin-left: 3px
}

/* .dragging { */
/*   opacity: 0.5 !important; */
/*   border: 2px dashed #000 !important; */
/* } */
